import Vue from 'vue'
// import '@/utils/elementui.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import { Carousel  } from 'element-ui';
import App from './App.vue'
import router from './router'
import apiFun from "@/request/api.js";
Vue.prototype.$apiFun = apiFun;//请求接口api
Vue.use(ElementUI);
import '@/assets/style/resrt.css'
// Vue.component(Carousel.name, Carousel);

Vue.config.productionTip = false

 
// // 设置浏览器标题
// Vue.directive('title', {
//   inserted: function (el, binding) {
//     document.title = el.dataset.title
//   }
// })


new Vue({
  render: h => h(App),
  router
}).$mount('#app')
