<template>
  <div class="box">
    <div class="header_img">
      <!-- <div class="box_search">
        <div class="search">
           <input type="search" name="" id="" value="搜索">
           <div>搜索</div>
        </div>
      </div> -->
    </div>
    <div class="navbar">
      <el-menu
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#d80d10"
        text-color="#fff"
        :default-active="defaultIndexNav"
        active-text-color="#ffd04b"
      >
        <el-menu-item :index="item.url.toString()" v-for="(item,index) in navBarList" :key="index"
          >
            <span>{{item.title}}</span></el-menu-item
        >
        <!-- <el-menu-item index="/XieHui"
          >
            <span @click="menuTitle(4)">协会概况</span></el-menu-item
        >
        <el-menu-item index="/Inform"
          >
            <span>新闻中心</span></el-menu-item
        >
        <el-menu-item index="4">
          <router-link :to="{ name: 'Work', params: { id: 1 } }">
            <span @click="menuTitle(6)">协会工作</span></router-link
          ></el-menu-item
        >
        <el-menu-item index="5">
          <router-link :to="{ name: 'Policy', params: { id: 1 } }">
            <span @click="menuTitle(7)">政策法规</span></router-link
          ></el-menu-item
        >
        <el-menu-item index="6">
          <router-link :to="{ name: 'Credit', params: { id: 1 } }">
            <span @click="menuTitle(8)">信用评价</span></router-link
          ></el-menu-item
        >
        <el-menu-item index="7">
          <router-link :to="{ name: 'Vip', params: { id: 1 } }">
            <span @click="menuTitle(9)">会员之家</span></router-link
          ></el-menu-item
        >
        <el-menu-item index="8">
          <router-link :to="{ name: 'DownLoad', params: { id: 1 } }">
            <span @click="menuTitle(10)">相关下载</span></router-link
          ></el-menu-item
        >
        <el-menu-item index="9">
          <router-link :to="{name : 'Consultation',params:{id:1}}">
            <span @click="menuTitle(11)">咨询平台</span></router-link
          >
        </el-menu-item> -->
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderBar",
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      defaultIndexNav:'',
      navBarList:[
        {
          title:'网站首页',
          url:'/',
        },
        {
          title:'协会概况',
          url:'/Intro',
        },
        {
          title:'新闻中心',
          url:'/Inform',
        },
        {
          title:'协会工作',
          url:'/Train',
        },
        {
          title:'政策法规',
          url:'/Country',
        },
        {
          title:'信用评价',
          url:'/CreditDetail',
        },
        {
          title:'会员之家',
          url:'/Unit',
        },
        {
          title:'相关下载',
          url:'/NewDownload',
        },{
          title:'咨询平台',
          url:'/ConsultationList',
        },
      ]
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      // this.defaultIndex = key
      this.$router.push(key.toString());

    },
    menuTitle(id) {
      if (id == 4) {
        localStorage.setItem("menuTitle", "协会简介");
      } else if (id == 5) {
        localStorage.setItem("menuTitle", "协会通知与公告");
      } else if (id == 6) {
        localStorage.setItem("menuTitle", "培训考核");
      } else if (id == 7) {
        localStorage.setItem("menuTitle", "国家政策法规");
      } else if (id == 8) {
        localStorage.setItem("menuTitle", "信用评价查询");
      } else if (id == 9) {
        localStorage.setItem("menuTitle", "会员单位");
      } else if (id == 10) {
        localStorage.setItem("menuTitle", "最新下载");
      } else if (id == 11) {
        localStorage.setItem("menuTitle", "最新下载");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  min-width: 100%;
  margin: 0 auto;
}
.header_img {
  /* height: 239px; */
  margin: 0 auto;
  /* background-color: orange; */
  height: 286px;
  width: 100%;
  /* min-width: 1200px; */
  background-image: url("@/assets/image/tb_bj.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  /* overflow: hidden; */
}
.box_search {
  width: 1200px;
  height: 286px;
  margin: 0 auto;
  position: relative;
}
.search {
  position: absolute;
  bottom: 50px;
  right: 0px;
  display: flex;
  flex-wrap: nowrap;
}
.navbar {
  width: 100%;
  height: 60px;
  background-color: #d80d10;
}
.el-menu-demo {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
}
.el-menu-item{
  font-size: 18px;
  line-height: 56px;
}


.router-link-active {
  text-decoration: none;
  color: #fff;
}
a {
  text-decoration: none;
  color: #fff;
}
</style>
