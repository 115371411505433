//引入刚才的http.js文件
import http from './http.js';
import https from './http.js';

//设置个对象，就不用一个个暴露了，直接暴露对象
let apiFun = {};

/* 获取列表 */
//查询列表，详情就是get
/* /api/getlist是请求接口地址，有http.js里面的Ip加上，如：http:192.168.0.1:9090//api/getlist  */
// apiFun.getlist = function(params) {
// 	return https.get('/api/getlist', params)
// }

// 获取协会简介
apiFun.getAssociation = function (params) {
	return https.get('api_index')
}
//获取协会章程
apiFun.getCharter = function (params) {
	return https.get('api_charter')
}
//获取入会须知
apiFun.getNotice = function (params) {
	return https.get('api_organization')
}
//所有列表接口
apiFun.getAllList = function (params) {
	return http.get('api_newsList', params)
}
//获取详情
apiFun.getDetail = function (params) {
	return http.get('api_newsItem', params)
}
//获取轮播图
apiFun.getCarousel = function (params) {
	return http.get('carouselList')
}
//获取会员风采轮播图
apiFun.getVipCarousel = function (params) {
	return http.get('carouselHy')
}

//暴露出这个对象
export default apiFun;

