import { render, staticRenderFns } from "./SpecialList.vue?vue&type=template&id=7441be90&scoped=true&"
import script from "./SpecialList.vue?vue&type=script&lang=js&"
export * from "./SpecialList.vue?vue&type=script&lang=js&"
import style0 from "./SpecialList.vue?vue&type=style&index=0&id=7441be90&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7441be90",
  null
  
)

export default component.exports