import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)


import Index from "@/components/Index/Index.vue"
import XieHui from "@/components/XieHui/XieHui.vue"
import News from "@/components/News/News.vue"
import NewDetail from "@/components/News/NewsList/NewDetail.vue"
import Inform from "@/components/News/NewsList/Inform.vue"
import Industry from "@/components/News/NewsList/Industry.vue"
import Intro from "@/components/XieHui/XiehuiList/Intro.vue"
import Statute from "@/components/XieHui/XiehuiList/Statute.vue"
import Relation from "@/components/XieHui/XiehuiList/Relation.vue"
import Notice from "@/components/XieHui/XiehuiList/Notice.vue"
import Framework from "@/components/XieHui/XiehuiList/Framework.vue"
import Work from "@/components/Work/Work.vue"
import WorkDetail from "@/components/Work/WorkList/WorkDetail.vue"
import Train from "@/components/Work/WorkList/Train.vue"
import Award from "@/components/Work/WorkList/Award.vue"
import Job from "@/components/Work/WorkList/Job.vue"
import Credit from "@/components/Credit/Credit.vue"
import DownLoad from "@/components/DownLoad/DownLoad.vue"
import Vip from "@/components/Vip/Vip.vue"
import Policy from "@/components/Policy/Policy.vue"
import Vedio from "@/components/Vedio/Vedio.vue"
import VedioList from "@/components/Vedio/VedioList.vue"
import VedioDetail from "@/components/Vedio/VedioDetail.vue"
// import VedioList from "@/components/Vedio/VedioList.vue"
import Detail from "@/components/Detail/Detail.vue"
import Country from "@/components/Policy/PolicyList/Country.vue"
import Province from "@/components/Policy/PolicyList/Province.vue"
import PolicyDetail from "@/components/Policy/PolicyList/PolicyDetail.vue"
import Unit from "@/components/Vip/VipList/Unit.vue"
import Dynamic from "@/components/Vip/VipList/Dynamic.vue"
import Change from "@/components/Vip/VipList/Change.vue"
import NewDownload from "@/components/DownLoad/NewDownload/NewDownload.vue"
import CreditDetail from "@/components/Credit/CreditDetail/CreditDetail.vue"
import XinCredit from "@/components/Credit/CreditDetail/XinCredit.vue"
import QualitySafe from "@/components/QualitySafe/QualitySafe.vue"
import QualityList from "@/components/QualitySafe/QualityList.vue"
import QualityDetail from "@/components/QualitySafe/QualityDetail.vue"
import Testing from "@/components/Testing/Testing.vue"
import TestingList from "@/components/Testing/TestingList.vue"
import TestingDetail from "@/components/Testing/TestingDetail.vue"
import Special from "@/components/Special/Special.vue"
import SpecialList from "@/components/Special/SpecialList.vue"
import SpecialDetail from "@/components/Special/SpecialDetail.vue"
import FireControl from "@/components/FireControl/FireControl.vue"
import FireControlList from "@/components/FireControl/FireControlList.vue"
import FireControlDetail from "@/components/FireControl/FireControlDetail.vue"
import Standard from "@/components/Standard/Standard.vue"
import StandardDetail from "@/components/Standard/StandardList/StandardDetail.vue"
import Quanguo from "@/components/Standard/StandardList/Quanguo.vue"
import QuanSheng from "@/components/Standard/StandardList/QuanSheng.vue"
import Benchmark from "@/components/Benchmark/Benchmark.vue"
import BenchmarkList from "@/components/Benchmark/BenchmarkList.vue"
import BenchmarkDetail from "@/components/Benchmark/BenchmarkDetail.vue"
import Consultation from "@/components/Consultation/Consultation.vue"
import ConsultationList from "@/components/Consultation/ConsultationList.vue"
import CarouselNav from "@/components/Carousel/CarouselNav.vue"
import CarouselList from "@/components/Carousel/CarouselList.vue"
import CarouselDetail from "@/components/Carousel/CarouselDetail.vue"
import VipDetail from "@/components/Vip/VipList/VipDetail.vue"
import DownloadDetail from "@/components/DownLoad/NewDownload/DownloadDetail.vue"

export default new Router({
    mode: 'hash',
    routes: [{
        path: '/',
        name: 'Home',
        component: Index,
    }, {
        path: '/XieHui:id',
        name: 'XieHui',
        component: XieHui,
        children: [
            {
                path: '/Intro',
                name: 'Intro',
                component: Intro
            }, {
                path: '/Statute',
                component: Statute
            }, {
                path: '/Framework',
                component: Framework
            }, {
                path: '/Notice',
                component: Notice
            }, {
                path: '/Relation',
                component: Relation
            }, {
                path: '/Detail',
                component: Detail
            }
        ]
    }, {
        path: '/News:id',
        name: 'News',
        component: News,
        children: [
            {
                path: '/Inform',
                name: 'Inform',
                component: Inform,
            },
            {
                path: '/Industry',
                // name: 'Industry',
                component: Industry,
            },
            {
                path: '/NewDetail',
                name: 'NewDetail',
                component: NewDetail,
            }
        ]
    }, {
        path: '/Work:id',
        name: 'Work',
        component: Work,
        children: [
            {
                path: '/Train',
                component: Train
            }, {
                path: '/Award',
                component: Award
            }, {
                path: '/Job',
                component: Job
            }, {
                path: '/WorkDetail',
                name: 'WorkDetail',
                component: WorkDetail
            }
        ]
    }, {
        path: '/Policy:id',
        name: 'Policy',
        component: Policy,
        children: [
            {
                path: '/Country',
                component: Country
            }, {
                path: '/Province',
                component: Province
            }, {
                path: '/PolicyDetail',
                name: "PolicyDetail",
                component: PolicyDetail
            }
        ]
    }, {
        path: '/Vip:id',
        name: 'Vip',
        component: Vip,
        children: [
            {
                path: '/Unit',
                component: Unit
            }, {
                path: '/Dynamic',
                component: Dynamic
            }, {
                path: '/Change',
                component: Change
            },{
                path:'/VipDetail',
                name:'VipDetail',
                component:VipDetail
            }
        ]
    }, {
        path: '/DownLoad:id',
        name: 'DownLoad',
        component: DownLoad,
        children: [
            {
                path: '/NewDownload',
                component: NewDownload
            },
            {
                path:'/DownloadDetail',
                name:'DownloadDetail',
                component:DownloadDetail
            }
            // ,{
            //     path:'/Job',
            //     component:Job
            // }
        ]
    }, {
        path: '/Credit:id',
        name: 'Credit',
        component: Credit,
        children: [
            {
                path: '/CreditDetail',
                component: CreditDetail
            },
            {
                path: '/XinCredit',
                name: 'XinCredit',
                component: XinCredit
            },
            // {
            //     path:'/Job',
            //     component:Job
            // }
        ]
    }, {
        path: '/Vedio:id',
        name: 'Vedio',
        component: Vedio,
        children: [
            {
                path: '/VedioDetail',
                name:'VedioDetail',
                component: VedioDetail
            },
            {
                path: '/VedioList',
                name:'VedioList',
                component: VedioList
            }
        ]
    }, {
        path: '/QualitySafe:id',
        name: 'QualitySafe',
        component: QualitySafe,
        children: [{
            path: '/QualityList',
            component: QualityList
        },
        {
            path: '/QualityDetail',
            name: 'QualityDetail',
            component: QualityDetail
        }
        ]
    }, {
        path: '/Testing:id',
        name: 'Testing',
        component: Testing,
        children: [{
            path: '/TestingList',
            component: TestingList
        },
        {
            path: '/TestingDetail',
            name: 'TestingDetail',
            component: TestingDetail
        }
        ]
    }, {
        path: '/Special:id',
        name: 'Special',
        component: Special,
        children: [{
            path: '/SpecialList',
            component: SpecialList
        },
        {
            path: '/SpecialDetail',
            name: 'SpecialDetail',
            component: SpecialDetail
        }
        ]
    }, {
        path: '/FireControl:id',
        name: 'FireControl',
        component: FireControl,
        children: [{
            path: '/FireControlList',
            component: FireControlList
        },
        {
            path: '/FireControlDetail',
            name: 'FireControlDetail',
            component: FireControlDetail
        }
        ]
    }, {
        path: '/Benchmark:id',
        name: 'Benchmark',
        component: Benchmark,
        children: [{
            path: '/BenchmarkList',
            component: BenchmarkList
        },
        {
            path: '/BenchmarkDetail',
            name: 'BenchmarkDetail',
            component: BenchmarkDetail
        }
        ]
    }, {
        path: '/Standard:id',
        name: 'Standard',
        component: Standard,
        children: [
            {
                path: '/Quanguo',
                name: 'Quanguo',
                component: Quanguo,
            },
            {
                path: '/QuanSheng',
                // name: 'Industry',
                component: QuanSheng,
            },
            {
                path: '/StandardDetail',
                name: 'StandardDetail',
                component: StandardDetail,
            }
        ]
    },{
        path:'/Consultation:id',
        name:'Consultation',
        component:Consultation,
        children:[
            {
                path:'/ConsultationList',
                component:ConsultationList
            }
        ]
    },{
        path:'/CarouselNav:id',
        name:'CarouselNav',
        component:CarouselNav,
        children:[
            {
                path:'/CarouselList',
                name:'CarouselList',
                component:CarouselList
            },
            {
                path:'/CarouselDetail',
                name:'CarouselDetail',
                component:CarouselDetail
            }
        ]
    }
    ]

})