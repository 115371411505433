<template>
  <div>
    <!-- 内容顶部 -->
    <div class="main-header">
      <div class="title">
        <h2>{{dataList.title}}</h2>
      </div>
      <div class="address">
        <span>时间：{{dataList.issue_date}}</span>
      </div>
    </div>
    <div class="main-box">
      <p v-html="dataList.content"></p>
      <!-- {{dataList.content}} -->
      </div>
    <div style="width：100% ;height:200px"></div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Intro",
  data() {
    return {
      dataList: [],
    };
  },
  created() {
    this.jianjie();
  },
  methods: {
    jianjie() {
      this.$apiFun
        .getCharter()
        .then((res) => {
          console.log(res, "协会章程");
          this.dataList= res.data
          console.log(1111);
        })
        .catch((err) => {
          console.log(err, "错误");
        });
      // axios.get({url:'http://8.129.86.81:7820/api/api_index?id=1'}).then(res=>{
      //   console.log(res)
      // })
    },
  },
};
</script>
<style  scoped>
/* .title{
    width: 90%;
    height: 60px;
    line-height: 60px;
    font-size: 24px;
    font-weight: 900;
} */
.box {
  width: 90%;
  height: 50px;
  font-size: 20px;
  border: 1px solid black;
  line-height: 50px;
}
.span {
  padding: 10px;
}
.main-header {
  width: 90%;
  height: 80px;
  padding-bottom: 30px;
  margin: 0 auto;
}

.title {
  width: 100%;
  height: 50px;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  line-height: 50px;
}
.address {
  width: 100%;
  height: 30px;
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
  line-height: 30px;
  white-space: pre-wrap;
}
.main-box {
  width: 90%;
  margin: 0 auto;
  line-height: 30px;
}
</style>
