import { render, staticRenderFns } from "./BenchmarkDetail.vue?vue&type=template&id=4f43c9e7&scoped=true&"
import script from "./BenchmarkDetail.vue?vue&type=script&lang=js&"
export * from "./BenchmarkDetail.vue?vue&type=script&lang=js&"
import style0 from "./BenchmarkDetail.vue?vue&type=style&index=0&id=4f43c9e7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f43c9e7",
  null
  
)

export default component.exports