<template>
  <div style="background-color:#f5f4f4">
    <HeaderBar></HeaderBar>
    <div class="box-body">
      <router-view></router-view>
    </div>
    <Footer></Footer>
    <RightNavbar></RightNavbar>
  </div>
</template>

<script>
// import Header from '@/components/Header/Header.vue'
import HeaderBar from "@/components/HeaderBar/HeaderBar.vue";
import Footer from '@/components/Footer/Footer.vue';
import RightNavbar from '@/components/HeaderBar/RightNavbar.vue';
export default {
  name: "App",
  components: {
    HeaderBar,
    // Index
    Footer,
    RightNavbar
  },
  watch: {
  //  $route(to, from) {
  //      window.location.reload(); //监测到路由发生跳转时刷新一次页面
  //  },
},

};
</script>

<style  scoped>
.box-body {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
}
</style>
