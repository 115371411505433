<template>
  <div class="footer">
    <div class="foot_a">
      <div class="wrap clearfix">
        <strong class="left">友情链接：</strong>
        <div class="fsel left">
          <select name="">
            <option value="">----主管部门----</option>

            <option value="http://www.mohurd.gov.cn">
              中华人民共和国住房和城乡建设部
            </option>
            <option value="http://zjt.hunan.gov.cn">
              湖南省住房和城乡建设厅
            </option>
            <option value="http://zjt.hunan.gov.cn/hngczl/">
              湖南省建设工程质量安全监督管理总站
            </option>
            <option value="http://www.hunanjs.gov.cn">湖南建设网</option>
          </select>
          <!-- <el-select v-model="value" placeholder="---主管部门---">
            <el-option
              v-for="item in select1"
              :key="item.value"
              :label="item.title"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
        </div>
        <div class="fsel left">
          <select name="">
            <option value="">----外省单位----</option>
            <option value="http://www.gxcic.net/">广西建设网</option>
            <option value="">河北建设工程信息网</option>
            <option value="">河南省工程建设信息网</option>
            <option value="">江西建设工程信息网</option>
            <option value="">湖北工程建设信息网</option>
            <option value="http://www.gdcic.net/">广东建设信息网</option>
            <option
              value="http://www.epicc.com.cn/ecar/indextb.jsp?ID=4301403276"
            >
              中国人保财险
            </option>
          </select>
        </div>
        <div class="fsel left">
          <select name="">
            <option value="">---市州---</option>
            <option value="http://szjw.changsha.gov.cn/jzgcaqjcz/">
              长沙市建筑工程安全监察站
            </option>
            <option value="http://szjw.changsha.gov.cn/jsgczljdz/">
              长沙市建设工程质量监督站
            </option>
            <option value="http://zjj.yzcity.gov.cn/">
              永州市住房和城乡建设局
            </option>
            <option value="http://220.168.210.91:8074/">
              常德市建设工程质量安全监督管理处
            </option>
            <option value="http://zjjzac.cn/">
              张家界市建设工程质量安全监督管理处
            </option>
            <option value="http://zjj.hnloudi.gov.cn/">
              娄底市住房和城乡建设局
            </option>
            <option value="http://www.yiyang.gov.cn/jsj/">
              益阳市住房和城乡建设局
            </option>
            <option value="http://xtjs.xiangtan.gov.cn/">
              湘潭市住房和城乡建设局
            </option>
            <option value="http://jsj.yueyang.gov.cn/">
              岳阳市住房和城乡建设局
            </option>
            <option value="http://zj.shaoyang.gov.cn/">
              邵阳市住房和城乡建设局
            </option>
            <option value="http://zjj.huaihua.gov.cn/">
              怀化市住房和城乡建设局
            </option>
            <option value="http://zjj.xxz.gov.cn/">
              湘西自治州住房和城乡建设局
            </option>
            <option value="http://www.hyjsjd.cn/">
              衡阳市建设工程质量监督站
            </option>
            <option value="http://www.hyjsaq.com/">
              衡阳市建设工程安全监督站
            </option>
            <option value="http://zjj.zhuzhou.gov.cn/">
              株洲市住房和城乡建设局
            </option>
          </select>
        </div>
        <!-- <div class="fsel left">
          <select name="">
            <option value="">技术支持</option>

            <option
              value="https://www.huaruan.com.cn/"
              title="广州市华软科技发展有限公司"
              target="_blank"
            >
              广州市华软科技发展有限公司
            </option>
          </select>
        </div> -->
      </div>
    </div>
    <div class="foot_b">
      <p>
        <span>主办：湖南省建设工程质量安全协会 </span>

        <span>湘备案号： <a target="_blank">湘ICP备20014079号</a></span>
        <!-- <span>版本号：V0.002-20200115</span> -->
      </p>
      <p>
        <!-- <span>传真：020-81253013</span>
        <span>邮箱：cisagd@163.com</span> -->
        <span> 电话：0731-88950613 电话：0731-88270456 </span>
        <span>地址：湖南省长沙市雨花区高升路268号和馨佳园4栋302</span>
      </p>
      <a
        href="https://xyt.xcc.cn/getpcInfo?sn=1582609476878282752&language=CN&certType=8&url=*.hngczl.com.cn"
        target="_blank"
        style="position: relative"
      >
        <div
          style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
        ></div>
        <embed
          src="https://program.xinchacha.com/web/1582609476878282752=*.hngczl.com.cn.svg"
          width="103"
          height="38"
          type="image/svg+xml"
          pluginspage="//www.adobe.com/svg/viewer/install/"
      /></a>
      <a
        href="https://xyt.xcc.cn/getpcInfo?sn=1582609476878282752&language=EN&certType=8&url=*.hngczl.com.cn"
        target="_blank"
        style="position: relative"
      >
        <div
          style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
        ></div>
        <embed
          src="https://program.xinchacha.com/web/1582609476878282752=*.hngczl.com.cnen.svg"
          width="103"
          height="38"
          type="image/svg+xml"
          pluginspage="//www.adobe.com/svg/viewer/install/"
      /></a>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      select1: [
        {
          value: "http://www.mohurd.gov.cn",
          title: "中华人民共和国住房和城乡建设部",
        },
        {
          value: "http://zjt.hunan.gov.cn",
          title: "湖南省住房和城乡建设厅",
        },
        {
          value: "http://zjt.hunan.gov.cn/hngczl/",
          title: "湖南省建设工程质量安全监督管理总站",
        },
        {
          value: "http://www.hunanjs.gov.cn",
          title: "湖南建设网",
        },
      ],
      select2: [
        {
          value: "http://www.gxcic.net/",
          title: "广西建设网",
        },
        {
          value: "",
          title: "河北建设工程信息网",
        },
        {
          value: "",
          title: "河南省工程建设信息网",
        },
        {
          value: "",
          title: "江西建设工程信息网",
        },
        {
          value: "",
          title: "湖北工程建设信息网",
        },
        {
          value: "http://www.gdcic.net/",
          title: "广东建设信息网",
        },
        {
          value: "http://www.epicc.com.cn/ecar/indextb.jsp?ID=4301403276",
          title: "中国人财保险",
        },
      ],
      select3: [
        {
          value: "http://szjw.changsha.gov.cn/jzgcaqjcz/",
          title: "长沙市建筑工程安全监察站",
        },
        {
          value: "http://szjw.changsha.gov.cn/jsgczljdz/",
          title: "长沙市建设工程质量监督站",
        },
        {
          value: "http://zjj.yzcity.gov.cn/",
          title: "永州市住房和城乡建设局",
        },
        {
          value: "http://220.168.210.91:8074/",
          title: "常德市建设工程质量安全监督管理处",
        },
        {
          value: "http://zjjzac.cn/",
          title: "张家界市建设工程质量安全监督管理处",
        },
        {
          value: "http://zjj.hnloudi.gov.cn/",
          title: "娄底市住房和城乡建设局",
        },
        {
          value: "http://www.yiyang.gov.cn/jsj/",
          title: "益阳市住房和城乡建设局",
        },
        {
          value: "http://xtjs.xiangtan.gov.cn/",
          title: "湘潭市住房和城乡建设局",
        },
        {
          value: "http://jsj.yueyang.gov.cn/",
          title: "岳阳市住房和城乡建设局",
        },
        {
          value: "http://zj.shaoyang.gov.cn/",
          title: "邵阳市住房和城乡建设局",
        },
        {
          value: "http://zjj.huaihua.gov.cn/",
          title: "怀化市住房和城乡建设局",
        },
        {
          value: "http://zjj.xxz.gov.cn/",
          title: "湘西自治州住房和城乡建设局",
        },
        {
          value: "http://www.hyjsjd.cn/",
          title: "衡阳市建设工程质量监督站",
        },
        {
          value: "http://www.hyjsaq.com/",
          title: "衡阳市建设工程安全监督站",
        },
        {
          value: "http://zjj.zhuzhou.gov.cn/",
          title: "株洲市住房和城乡建设局",
        },
      ],
    };
  },
  methods: {
    herf(value) {
      console.log(value, "网址");
      // window.location.href=
    },
  },
};
</script>
<style scoped>
.footer {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  margin: 0 auto;
}
.wrap {
  width: 1200px;
  height: 72px;
  line-height: 72px;
  /* display: flex;
    flex-flow: nowrap; */
  margin: 0 auto;
  padding-right: 15px;
}
.clearfix {
  display: flex;
  flex-flow: nowrap;
  justify-content: space-evenly;
}
.foot_a {
  background-color: #e6e4e5;
  padding: 15px 0;
}
.foot_a strong {
  font-size: 18px;
  color: #d80d10;
  height: 42px;
  /* line-height: 42px; */
}
.fsel select {
  width: 200px;
  height: 42px;
  line-height: 42px;
  border: none;
  padding: 0 15px;
  width: 100%;
  position: relative;
  z-index: 2;
}
.left {
  display: inline-block;
}
.foot_b {
  text-align: center;
  padding: 20px 0;
  border-top: 4px solid #d80d10;
}
.foot_b p {
  padding: 5px 0;
}
</style>
