<template>
  <div>
    <div class="title">
      <span class="span">{{ title }}</span>
    </div>
    <!-- <div v-for="(item, index) in dataList" :key="index" class="box">
      <span class="span">{{ item.title }}</span>
    </div> -->

    <div class="page-box">
      <div class="card box-card">
        <div
          v-for="(item, index) in dataList"
          :key="index"
          class="text item flex_"
          :class="index % 2 !== 1 ? 'danshu' : ''"
          @click="GoDetail(item)"
        >
         
            <div class="item_title">{{ item.title }}</div>
        
          <div class="item_time">{{ item.issue_date }}</div>
        </div>
        <div style="width: 100%; height: 100px"></div>
        <div v-if="count>10">
          <Pagination
            :valueList="formData"
            :total="count"
            @changePage="Change"
          ></Pagination>
        </div>
      </div>
    </div>
    <!-- 虚线 -->
  </div>
</template>

<script>
import Pagination from "@/components/Pagination/Pagination.vue";

export default {
  name: "Award",
  components: {
    Pagination,
  },
  data() {
    return {
      dataList: [],
      formData: {
        type: 4,
        limit: 20,
        page: 1,
      },
      title: "评优评奖",
      count: null,
    };
  },
  created() {
    this.getAwardList();
    this.Change();
  },
  methods: {
    getAwardList() {
      this.$apiFun.getAllList(this.formData).then((res) => {
        console.log(res);
        this.dataList = res.data.data;
        this.count = res.data.count;
      });
    },
    Change(e) {
      console.log(e, "子传父");
      this.formData = {
        type: 4,
        limit: 20,
        page: e != undefined ? e : 1,
      };
      console.log(this.formData);
      this.getAwardList();
    },
    GoDetail(item) {
      console.log(item)
      if(item.class_type==0 || item.class_type==null){  //有无链接
      console.log(item.id,"ididididi")
        this.$router.push({ name: "WorkDetail", query: { id: item.id } });
      }else if(item.class_type==1){
        // 跳转至外网链接
        // console.log(item.address)
      window.location.href= item.address //url链接
      }
    },
  },
};
</script>
<style  scoped>
.title {
  width: 90%;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  font-weight: 900;
  margin: 0 auto;
  /* border-bottom: 1px solid black; */
}
.box {
  width: 100%;
  height: 50px;
  font-size: 20px;
  border: 1px solid black;
  line-height: 50px;
}
.span {
  /* padding-left: 24px; */
  display: block;
  border-bottom: 1px solid rgb(245, 244, 244);
  width: 848px;
  margin: 0 auto;
}
.page-box {
  width: 850px;
  /* min-height: 700px; */
  /* background-color: rgb(0, 0, 0); */
  padding: 10px;
  padding-top: 20px;
  margin: 0 auto;
  border-bottom: rgb(245, 244, 244) 1px dashed;
}
.card {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
}
.item {
  width: 100%;
  /* padding: 18px 0; */
  height: 48px;
}
.danshu {
  background-color: rgb(245, 244, 244);
}
.box-card {
  width: 100%;
  /* height: 467px; */
  /* padding: 0px 20px 0px 0px; */
}
.flex_ {
  display: flex;
  justify-content: space-between;
}
.text {
  font-size: 14px;
}
.item_title {
  width: 700px;
  height: 48px;
  line-height: 48px;
  color: #555555;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 400ms;
  padding-left: 10px;
}
.item_title:hover {
  color: red;
  transform: translateX(10px);
  transition: all 400ms;
  cursor: pointer;
}
.item_time {
  width: 10%;
  height: 48px;
  line-height: 48px;
  color: #555555;
}
</style>

