<template>
  <div>
    <!-- <HeaderBar></HeaderBar> -->
    <div class="page-box">
      <div class="card">
        <!-- 轮播图 -->
        <div class="block">
          <Carousel style="margin-top: 50px"></Carousel>
        </div>
        <el-card class="top left">
          <div
            slot="header"
            class="clearfix top"
            style="
              display: flex;
              flex: flow;
              flex-warp: nowarp;
              justify-content: space-between;
            "
          >
            <h1 class="clearfix_title">通知公告</h1>
            <h5 class="clearfix_title_more" @click="goMoreNew()">更多>></h5>
          </div>
          <div
            v-for="(item, index) in array"
            :key="index"
            class="text top_content flex_"
            @click="GoDetail(item)"
          >
            <div class="item_title_top">
              {{ item.title }}
            </div>
            <div class="item_time">{{ item.issue_date }}</div>
          </div>
        </el-card>

        <el-card class="right">
          <!-- <div class="imgbox"> -->
          <div style="width: 100%; height: 40px"></div>
          <div class="imgroad">
            <img
              @click="goBenchmark()"
              class="imgBanner"
              src="@/assets/image/hybg.png"
              alt=""
            />
          </div>
          <div class="imgroad">
            <img
              @click="goCountry()"
              class="imgBanner"
              src="@/assets/image/quanguo.png"
              alt=""
            />
          </div>
          <div class="imgroad">
            <img
              @click="goProvince()"
              class="imgBanner"
              src="@/assets/image/quansheng.png"
              alt=""
            />
          </div>
          <div class="imgroad">
            <!-- <router-link :to="{name:'Vip',params:{id:1}}"> -->
            <img
              @click="goVip()"
              class="imgBanner"
              src="@/assets/image/vip.png"
              alt=""
            />
            <!-- </router-link> -->
          </div>
          <!-- </div> -->
        </el-card>
      </div>
    </div>
    <div class="page-box">
      <div class="card">
        <!-- 通知公告 -->
        <el-card class="top left">
          <div
            slot="header"
            class="clearfix top"
            style="
              display: flex;
              flex: flow;
              flex-warp: nowarp;
              justify-content: space-between;
            "
          >
            <h1 class="clearfix_title">工作动态</h1>
            <h5 class="clearfix_title_more" @click="goMoreNewTwo()">更多>></h5>
          </div>
          <div
            v-for="(item, index) in data2"
            :key="index"
            class="text top_content flex_"
          >
            <!-- <router-link :to="{name:'Detail', query: {id:item.id,type:3}}"> -->
            <div class="item_title_top" @click="GoDetailOne(item)">
              {{ item.title }}
            </div>
            <!-- </router-link> -->
            <div class="item_time">{{ item.issue_date }}</div>
          </div>
        </el-card>
        <!-- 工作动态 -->
        <el-card class="top left">
          <div
            slot="header"
            class="clearfix top"
            style="
              display: flex;
              flex: flow;
              flex-warp: nowarp;
              justify-content: space-between;
            "
          >
            <h1 class="clearfix_title">党建工作</h1>
            <h5 class="clearfix_title_more" @click="goMoreDang()">更多>></h5>
          </div>
          <div
            v-for="(item, index) in data3"
            :key="index"
            class="text top_content flex_"
          >
            <!-- <router-link :to="{name:'Detail', query: {id:item.id,type:3}}"> -->
            <div class="item_title_top" @click="GoDetailTwo(item)">
              {{ item.title }}
            </div>
            <!-- </router-link> -->
            <div class="item_time">{{ item.issue_date }}</div>
          </div>
        </el-card>
        <el-card class="right" :body-style="cardStyle">
          <div slot="header" class="banshi">
            <h1 class="clearfix_title">办事入口</h1>
          </div>
          <div class="img-box">
            <div class="img-road">
              <img
                class="imgBanner"
                src="@/assets/image/xinyong.png"
                alt=""
                @click="goConstruction(1)"
              />
            </div>
            <div class="img-road">
              <img
                class="imgBanner"
                src="@/assets/image/banshi_02.png"
                alt=""
                @click="goConstruction(2)"
              />
            </div>
            <div class="img-road">
              <img
                class="imgBanner"
                src="@/assets/image/banshi_03.png"
                alt=""
                @click="goConstruction(3)"
              />
            </div>
            <div class="img-road">
              <img
                class="imgBanner"
                src="@/assets/image/banshi_05.png"
                alt=""
                @click="goConstruction(4)"
              />
            </div>
            <div class="img-road">
              <img
                class="imgBanner"
                src="@/assets/image/banshi_06.png"
                alt=""
                 @click="goConstruction(5)"
              />
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div style="width: 1160px; margin: 0px 20px 0px 20px">
      <!-- <router-link to="/Vedio"> -->
      <div class="content_img" @click="GoVedio()"></div>
      <!-- </router-link> -->
    </div>
    <div class="page-box">
      <div class="card">
        <!-- 通知公告 -->
        <el-card class="bottom left">
          <div
            slot="header"
            class="clearfix bottom"
            style="
              display: flex;
              flex: flow;
              flex-warp: nowarp;
              justify-content: space-between;
            "
          >
            <h1 class="clearfix_title">质量安全资讯</h1>
            <h5 class="clearfix_title_more" @click="goMoreSafe">更多>></h5>
          </div>
          <div
            v-for="(item, index) in data4"
            :key="index"
            class="text bottom_content flex_"
          >
            <!-- <router-link :to="{name:'Detail', query: {id:item.id,type:15}}"> -->
            <div class="item_title_bottom" @click="GoDetailSen(item)">
              {{ item.title }}
            </div>
            <!-- </router-link> -->
            <div class="item_time">{{ item.issue_date }}</div>
          </div>
        </el-card>
        <!-- 工作动态 -->
        <el-card class="bottom left">
          <div
            slot="header"
            class="clearfix bottom"
            style="
              display: flex;
              flex: flow;
              flex-warp: nowarp;
              justify-content: space-between;
            "
          >
            <h1 class="clearfix_title">检测资讯</h1>
            <h5 class="clearfix_title_more" @click="goMoreTesting">更多>></h5>
          </div>
          <div
            v-for="(item, index) in data5"
            :key="index"
            class="text bottom_content flex_"
          >
            <!-- <router-link :to="{name:'Detail', query: {id:item.id,type:16}}"> -->
            <div class="item_title_bottom" @click="GoDetailFore(item)">
              {{ item.title }}
            </div>
            <!-- </router-link> -->
            <div class="item_time">{{ item.issue_date }}</div>
          </div>
        </el-card>
        <el-card class="bottom left">
          <div
            slot="header"
            class="clearfix bottom"
            style="
              display: flex;
              flex: flow;
              flex-warp: nowarp;
              justify-content: space-between;
            "
          >
            <h1 class="clearfix_title">特种设备资讯</h1>
            <h5 class="clearfix_title_more" @click="goMoreSpecial">更多>></h5>
          </div>
          <div
            v-for="(item, index) in data6"
            :key="index"
            class="text bottom_content flex_"
          >
            <!-- <router-link :to="{name:'Detail', query: {id:item.id,type:15}}"> -->
            <div class="item_title_bottom" @click="GoDetailFive(item)">
              {{ item.title }}
            </div>
            <!-- </router-link> -->
            <div class="item_time">{{ item.issue_date }}</div>
          </div>
        </el-card>
        <!-- 工作动态 -->
        <el-card class="bottom">
          <div
            slot="header"
            class="clearfix bottom"
            style="
              display: flex;
              flex: flow;
              flex-warp: nowarp;
              justify-content: space-between;
            "
          >
            <h1 class="clearfix_title">消防资讯</h1>
            <h5 class="clearfix_title_more" @click="goMoreFireControl">
              更多>>
            </h5>
          </div>
          <div
            v-for="(item, index) in data7"
            :key="index"
            class="text bottom_content flex_"
          >
            <div class="item_title_bottom" @click="GoDetailSix(item)">
              {{ item.title }}
            </div>
            <div class="item_time">{{ item.issue_date }}</div>
          </div>
        </el-card>
      </div>
    </div>

    <!-- 下方图片展示 -->
    <div style="width: 1160px; margin: 0px 20px 0px 20px">
      <el-card class="image-box">
        <div slot="header" class="clearfix">
          <h1 class="clearfix_title">会员风采展示</h1>
        </div>
        <div>
          <BaseSwiper></BaseSwiper>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import HeaderBar from "@/components/HeaderBar/HeaderBar.vue";
import Carousel from "@/components/Carousel/Carousel.vue";
import BaseSwiper from "@/components/Carousel/BaseSwiper.vue";
export default {
  name: "Index",
  components: {
    HeaderBar,
    Carousel,
    BaseSwiper,
  },
  data() {
    return {
      cardStyle: {
        padding: "0px 20px",
      },
      data1: [],
      data2: [],
      data3: [],
      data4: [],
      data5: [],
      data6: [],
      data7: [],
      urls: [
        { url: "../../assets/image/01.jpg" },
        { url: "../../assets/image/02.jpg" },
        { url: "../../assets/image/03.jpg" },
        { url: "../../assets/image/04.jpg" },
        { url: "../../assets/image/05.jpg" },
        { url: "../../assets/image/06.jpg" },
      ],
      urls2: [
        { url: "../../assets/image/07.jpg" },
        { url: "../../assets/image/08.jpg" },
        { url: "../../assets/image/09.jpg" },
        { url: "../../assets/image/10.jpg" },
      ],
      images: [
        {
          src: "../../assets/image/hybg.png",
        },
        {
          src: "../../assets/image/vip.png",
        },
        {
          src: "../../assets/image/quanguo.png",
        },
        {
          src: "../../assets/image/quansheng.png",
        },
      ],
      images1: [
        {
          src: "../../assets/image/xinyong.png",
        },
        {
          src: "../../assets/image/banshi_02.png",
        },
        {
          src: "../../assets/image/banshi_04.png",
        },
        {
          src: "../../assets/image/banshi_04.png",
        },
      ],
      // tableData: [
      //   { url: require("@/assets/image/01.jpg"), title: "顶部轮播图01" },
      //   { url: require("@/assets/image/02.jpg"), title: "顶部轮播图02" },
      //   { url: require("@/assets/image/03.jpg"), title: "顶部轮播图03" },
      //   { url: require("@/assets/image/04.jpg"), title: "顶部轮播图04"},
      //   { url: require("@/assets/image/05.jpg"), title: "顶部轮播图05" },
      //   { url: require("@/assets/image/06.jpg"), title: "顶部轮播图06" },
      // ],
      formData1: {
        page: 1,
        limit: 8,
        type: 1, //通知公告
      },
      formData2: {
        page: 1,
        limit: 10,
        type: 2, //工作动态
      },
      formData3: {
        page: 1,
        limit: 10,
        type: 5, //党建工作
      },
      formData4: {
        page: 1,
        limit: 8,
        type: 27, //质量安全咨讯
      },
      formData5: {
        page: 1,
        limit: 8,
        type: 28, //检测咨讯
      },
      formData6: {
        page: 1,
        limit: 8,
        type: 29, //特种设备咨讯
      },
      formData7: {
        page: 1,
        limit: 8,
        type: 30, //消防咨讯
      },

      array: [],
      _item: [],
    };
  },
  created() {
    this.get();
    // this.array.unshift(this._item[0])
    this.getTongzhi();
  },
  methods: {
    get() {
      this.$apiFun
        .getAllList({ page: 1, limit: 99999, type: 1 })
        .then((res) => {
          console.log(res.data.data)
          
          for(let i=0;i<7;i++){
            this.array.push(res.data.data[i])
          }
          let arr = []
         res.data.data.forEach(item=>{
            if(item.issue_date === '2022-11-14'){
              console.log(item)
               this.array.unshift(item)
            }
          })
        });
    },
    getTongzhi(item) {
      // this.$apiFun.getAllList(this.formData1).then((res) => {
      //   this.array = res.data.data;
      //   console.log(this.array);
      // });
      this.$apiFun.getAllList(this.formData2).then((res) => {
        this.data2 = res.data.data;
      });
      this.$apiFun.getAllList(this.formData3).then((res) => {
        this.data3 = res.data.data;
      });
      this.$apiFun.getAllList(this.formData4).then((res) => {
        this.data4 = res.data.data;
      });
      this.$apiFun.getAllList(this.formData5).then((res) => {
        this.data5 = res.data.data;
      });
      this.$apiFun.getAllList(this.formData6).then((res) => {
        this.data6 = res.data.data;
      });
      this.$apiFun.getAllList(this.formData7).then((res) => {
        this.data7 = res.data.data;
      });
    },
    GoDetail(item) {
      if (item.class_type == 0 || item.class_type == null) {
        //有无链接
        // console.log(item.address)
        this.$router.push({ name: "NewDetail", query: { id: item.id } });
        localStorage.setItem("menuTitle", "协会通知与公告");
      } else if (item.class_type == 1) {
        // 跳转至外网链接
        console.log(item.address);
        window.location.href = item.address; //url链接
      }
    },
    GoDetailOne(item) {
      if (item.class_type == 0 || item.class_type == null) {
        //有无链接
        // console.log(item.address)
        this.$router.push({
          name: "NewDetail",
          query: { id: item.id, title: "工作动态" },
        });
        // localStorage.setItem("menuTitle", "工作动态");
      } else if (item.class_type == 1) {
        // 跳转至外网链接
        console.log(item.address);
        window.location.href = item.address; //url链接
      }
    },
    GoDetailTwo(item) {
      if (item.class_type == 0 || item.class_type == null) {
        //有无链接
        // console.log(item.address)
        this.$router.push({ name: "WorkDetail", query: { id: item.id } });
        localStorage.setItem("menuTitle", "党建工作");
      } else if (item.class_type == 1) {
        // 跳转至外网链接
        console.log(item.address);
        window.location.href = item.address; //url链接
      }
    },
    GoDetailSen(item) {
      if (item.class_type == 0 || item.class_type == null) {
        //有无链接
        // console.log(item.address)
        this.$router.push({ name: "QualityDetail", query: { id: item.id } });
      } else if (item.class_type == 1) {
        // 跳转至外网链接
        console.log(item.address);
        window.location.href = item.address; //url链接
      }
    },
    GoDetailFore(item) {
      if (item.class_type == 0 || item.class_type == null) {
        //有无链接
        // console.log(item.address)
        this.$router.push({ name: "TestingDetail", query: { id: item.id } });
      } else if (item.class_type == 1) {
        // 跳转至外网链接
        console.log(item.address);
        window.location.href = item.address; //url链接
      }
    },
    GoDetailFive(item) {
      if (item.class_type == 0 || item.class_type == null) {
        //有无链接
        // console.log(item.address)
        this.$router.push({ name: "SpecialDetail", query: { id: item.id } });
      } else if (item.class_type == 1) {
        // 跳转至外网链接
        console.log(item.address);
        window.location.href = item.address; //url链接
      }
    },
    GoDetailSix(item) {
      if (item.class_type == 0 || item.class_type == null) {
        //有无链接
        // console.log(item.address)
        this.$router.push({
          name: "FireControlDetail",
          query: { id: item.id },
        });
      } else if (item.class_type == 1) {
        // 跳转至外网链接
        console.log(item.address);
        window.location.href = item.address; //url链接
      }
    },
    GoDetailTop(item) {
      if (item.class_type == 0 || item.class_type == null) {
        //有无链接
        this.$router.push({ name: "NewDetail", query: { id: item.id } });
      } else if (item.class_type == 1) {
        // 跳转至外网链接
        console.log(item.address);
        // window.location.href = item.address; //url链接
      }
    },
    goConstruction(index) {
      if (index == 1) {
        window.location.href = "http://132.232.170.50:9102/ToLogin.aspx";
      }
      if (index == 2) {
        window.location.href = "http://132.232.170.50:9102/Login.aspx"; //url链接
      }
      if (index == 3) {
        window.location.href = "https://www.hnjsrcw.com/QueryCert.html";
      }
      if (index == 4) {
        window.location.href = "http://113.247.238.147:9101/hnqzjxgl/login.jsp"; //url链接
      }
      if(index==5){
        window.location.href = "http://132.232.170.50:9015/Login.aspx"
      }
    },
    GoVedio() {
      this.$router.push({ name: "Vedio", params: { id: 1 } });
      localStorage.setItem("menuTitle", "视频文件");
    },
    goQuality() {
      this.$router.push({ name: "QualitySafe", params: { id: 1 } });
    },
    goBenchmark() {
      this.$router.push({ name: "Benchmark", params: { id: 1 } });
    },
    goCountry() {
      this.$router.push({ name: "Standard", params: { id: 1 } });
      localStorage.setItem("menuTitle", "全国安全生产标准化工地");
    },
    goProvince() {
      this.$router.push({ name: "Standard", params: { id: 2 } });
      localStorage.setItem("menuTitle", "全省安全生产标准化工地");
    },
    goVip() {
      this.$router.push({ name: "Vip", params: { id: 1 } });
      localStorage.setItem("menuTitle", "会员单位");
    },
    // 更多通告
    goMoreNew() {
      this.$router.push({ name: "News", params: { id: 1 } });
      localStorage.setItem("menuTitle", "协会通知与公告");
    },
    // 工作动态
    goMoreNewTwo() {
      this.$router.push({ name: "News", params: { id: 2 } });
      localStorage.setItem("menuTitle", "工作动态");
    },
    //党建工作
    goMoreDang() {
      this.$router.push({ name: "Work", params: { id: 3 } });
    },
    //质量安全咨讯
    goMoreSafe() {
      this.$router.push({ name: "QualitySafe", params: { id: 1 } });
    },
    goMoreTesting() {
      this.$router.push({ name: "Testing", params: { id: 1 } });
    },
    goMoreSpecial() {
      this.$router.push({ name: "Special", params: { id: 1 } });
    },
    goMoreFireControl() {
      this.$router.push({ name: "FireControl", params: { id: 1 } });
    },
  },
};
</script>
<style scoped>
.page-box {
  width: 1160px;
  /* height: 1000px; */
  /* background-color: rgb(0, 0, 0); */
  padding: 20px;
  margin: 0 auto;
}
.clearfix {
  width: 100%;
  height: 38px;
  /* background-color: #d86c6c; */
  border-bottom: 2px solid #d86c6c;
}
.banshi {
  width: 300px;
  height: 38px;
  /* background-color: #d86c6c; */
  border-bottom: 2px solid #d86c6c;
}
.clearfix_title {
  /* padding: 20px 50px; */
  width: 135px;
  height: 38px;
  background-color: #d80d10;
  text-align: center;
  line-height: 38px;
  color: #fff;
  font-size: 18px;
  font: bold;
}
/* .clearfix_title:hover{
  cursor: pointer;
} */
.clearfix_title_more {
  width: 85px;
  height: 20px;
  margin-top: 9px;
  margin-right: 20px;
  background-color: #f3f3f3b2;
  text-align: center;
  line-height: 20px;
  color: #d80d10;
  font-size: 8px;
  font: bold;
}
.clearfix_title_more:hover {
  cursor: pointer;
}
.card {
  display: flex;
}

.text {
  font-size: 14px;
}
.top {
  width: 408px;
}
.top_content {
  width: 368px;
}
.left {
  margin-right: 20px;
}
.right {
  width: 300px;
}
.item {
  width: 570px;
  /* padding: 18px 0; */
}
.flex_ {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.item_title_top {
  width: calc(368px - 80px);
  height: 35px;
  line-height: 35px;
  color: #555555;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 400ms;
}
.item_title_top:hover {
  color: red;
  transform: translateX(10px);
  transition: all 400ms;
  cursor: pointer;
}
.item_title_bottom {
  width: calc(235px - 80px);
  height: 35px;
  line-height: 35px;
  color: #555555;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 400ms;
}
.item_title_bottom:hover {
  color: red;
  transform: translateX(10px);
  transition: all 400ms;
  cursor: pointer;
}
.item_title {
  width: calc(570px - 80px);
  height: 35px;
  line-height: 35px;
  color: #555555;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 400ms;
}
.item_title:hover {
  color: red;
  transform: translateX(10px);
  transition: all 400ms;
  cursor: pointer;
}
.item_time {
  width: 80px;
  height: 35px;
  line-height: 35px;
  color: #555555;
}
.box-card {
  width: 570px;
  /* height: 467px; */
  padding: 0px 20px 0px 0px;
}
.content_img {
  width: 100%;
  height: 203.98px;
  background-image: url("@/assets/image/spbf.png");
}
.block {
  width: 408px;
  height: 300px;
  padding-right: 20px;
}
.imgBanner {
  width: 260px;
  height: 100%;
}
.img-box {
  width: 300px;
  height: 280px;
  margin-top: 20px;
}
.img-road {
  width: 300px;
  height: 60px;
  margin-bottom: 10px;
}
.img-road {
  cursor: pointer;
}
.imgroad {
  cursor: pointer;
}
/* .imgbox{
  width: 300px;
  height: 320px;
  margin-top: 20px;
} */
.imgroad {
  width: 300px;
  height: 60px;
  margin-bottom: 22px;
}
.bottom {
  width: 275px;
}
.bottom_content {
  width: 235px;
}
.right-navbar {
  width: 70px;
  height: 290px;
  /* background-color: red; */
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  position: fixed;
  top: 200px;
  right: 10px;
}
.right-nav-div {
  width: 70px;
  height: 70px;
  box-shadow: 0px 2px 3px #ccc;
}
.right-nav-div:hover {
  cursor: pointer;
}
.right-nav-div2 {
  width: 70px;
  height: 70px;
  box-shadow: 0px 2px 3px #ccc;
}
.right-nav-div2 div {
  width: 200px;
  height: 181px;
  position: absolute;
  left: -200px;
  top: 71px;
  display: none;
}
.right-nav-div2:hover {
  cursor: pointer;
}
.right-nav-div2:hover div {
  display: block;
}
</style>
