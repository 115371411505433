import axios from 'axios'     //引入
// import { Toast } from 'vant'; 

const _axios = axios.create({
    baseURL: 'https://hngczl.com.cn:803/api' ,
    // baseURL:'http://8.129.86.81:7820/api/',
    timeout: 10000
})
/* 请求拦截器（请求之前的操作） */
_axios.interceptors.request.use(
    config => {
        //如果有需要在这里开启请求时的loading动画效果  
        return config
    },
    err => Promise.reject(err)
);

/* 请求之后的操作 */
_axios.interceptors.response.use(
    res => {
        //在这里关闭请求时的loading动画效果
        //这里用于处理返回的结果，比如如果是返回401无权限，可能会是跳回到登录页的操作，结合自己的业务逻辑写
        if (res.data.code === 401) {
            console.log("无权限操作")
        }
        if (res.data.code === 400) {
            console.log("请求网络失败")
        }
        if (res.data.code === 404) {
            console.log("请求网络失败")
        }
        return res;
    },
    err => {
        if (err) {
            //在这里关闭请求时的loading动画效果
            console.log("请求网络失败")
        }
        return Promise.reject(err);
    }
);

//封装post,get方法
const http = {
    get(url = '', params = {}) {
        return new Promise((resolve, reject) => {
            _axios.get(url, {
                params: params
            }).then(res => {
                resolve(res.data)
                return res
            }).catch(error => {
                reject(error)
            })
        })
    },
    post(url = '', params = {}) {
        return new Promise((resolve, reject) => {
            _axios.post({
                url,
                data: params,
            }).then(res => {
                resolve(res.data)
                return res
            }).catch(error => {
                reject(error)
            })
        })
    }
}


export default http
