<template>
  <div class="carouselBox">
    <el-carousel :loop="true" :autoplay="true" class="carousel" height="184px">
      <el-carousel-item
        class="el-car-item"
        v-for="(list, index) in dataList"
        :key="index"
      >
        <div v-for="(imgList, index1) in list" :key="index1" class="divSrc" @click="goCarouselDetail(imgList)">
          <img class="img" :src="imgList.img" />
          <div class="title">{{ imgList.title }}</div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default{
 data(){
      return{
        dataList:[]
      }
 },
  mounted(){
			// this.byEvents();
      this.getCarousel()
		},
		methods: {
			byEvents() {
        
				// this.dataList = [{
				// 		img: require('@/assets/image/01.jpg'),
				// 		title: '轮播图'
				// 	},{
				// 		img: require('@/assets/image/02.jpg'),
				// 		title: '轮播图02'
				// 	},{
				// 		img: require('@/assets/image/03.jpg'),
				// 		title: '轮播图03'
				// 	},{
				// 		img: require('@/assets/image/04.jpg'),
				// 		title: '轮播图04'
				// 	},{
				// 		img: require('@/assets/image/05.jpg'),
				// 		title: '轮播图05'
				// 	},{
				// 		img: require('@/assets/image/06.jpg'),
				// 		title: '轮播图06'
				// 	},{
				// 		img: require('@/assets/image/01.jpg'),
				// 		title: '轮播图07'
				// 	},{
				// 		img: require('@/assets/image/02.jpg'),
				// 		title: '轮播图08'
				// 	},{
				// 		img: require('@/assets/image/03.jpg'),
				// 		title: '轮播图09'
				// 	},{
				// 		img: require('@/assets/image/04.jpg'),
				// 		title: '轮播图10'
				// 	},{
				// 		img: require('@/assets/image/05.jpg'),
				// 		title: '轮播图11'
				// 	},{
				// 		img: require('@/assets/image/06.jpg'),
				// 		title: '轮播图12'
				// 	},
					
				// ]
				
				let newDataList = [];
				let current = 0
				if (this.dataList && this.dataList.length > 0) {
					for (let i = 0; i <= this.dataList.length - 1; i++) {
						if (i % 4 !== 0 || i === 0) {	//数据处理成几张展示
							if (!newDataList[current]) {
								newDataList.push([this.dataList[i]])
							} else {
								newDataList[current].push(this.dataList[i])
							}
						} else {
							current++
							newDataList.push([this.dataList[i]])
						}
					}
				}
				this.dataList = [...newDataList]

			},
			//获取轮播图
      getCarousel(){
        this.$apiFun.getVipCarousel().then(res=>{
          console.log(res.data.data,"轮播图")
          this.dataList=[...this.dataList,...res.data.data]
          console.log(this.dataList)
          // eslint-disable-next-line no-mixed-spaces-and-tabs
          	let newDataList = [];
            let current = 0
            if (this.dataList && this.dataList.length > 0) {
              for (let i = 0; i <= this.dataList.length - 1; i++) {
                if (i % 4 !== 0 || i === 0) {	//数据处理成几张展示
                  if (!newDataList[current]) {
                    newDataList.push([this.dataList[i]])
                  } else {
                    newDataList[current].push(this.dataList[i])
                  }
                } else {
                  current++
                  newDataList.push([this.dataList[i]])
                }
              }
            }
				this.dataList = [...newDataList]
        })
      },
      goCarouselDetail(item){
        console.log(item)
         this.$router.push({ name: "CarouselDetail", query: { id: item.id } });
      }
			
		}
}
	
</script>
<style lang="scss" scoped>
.carouselBox {
  margin: 0 auto;
  width: 100%;
  height: 184px;
  .carousel {
    width: 100%;
    height: 184px;
    .el-car-item {
      width: 100%;
      height: 184px;
      display: flex;
      justify-content: space-between;
      .divSrc {
        width: 23%;
        height: 184px;
        background: #fff;
        position: relative;
        .img {
          width: 100%;
          // height: 100%;
        }
        .title {
          width: 100%;
          height: 30px;
          line-height: 30px;
          margin: 0 auto;
          text-align: center;
          font-size: 16px;
          // font-weight: bold;
          background-color: rgba(0, 0, 0, 0.6);
          color: #fff;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          position: absolute;
          bottom: 0px;
          cursor: pointer;
        }
      }
    }
    /deep/.el-carousel__arrow {
      background: rgba(0,0,0,0.5) !important;
      display: block !important;
    }
  }
  .el-car-item {
    width: 100%;
    height: 184px;
    display: flex;
    .img {
      width: 284px;
      height: 184px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}
</style>

