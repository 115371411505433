<template>
  <div class="swiper" @mouseover="overHd" @mouseout="auto">
    <div
      class="swiper-item"
      v-for="(item, ind) in tableData"
      :key="item.src"
      v-show="ind == index"
      @click="goCarouselDetail(item)"
    >
      <img :src="item.src" width="408px" height="300px" />
      <div class="title">{{ item.title }}</div>
    </div>

    <div class="thumbs">
      <span
        class="thumb"
        :class="{ active: item == index + 1 }"
        v-for="item in tableData.length"
        :key="item"
        @click="index = item - 1"
        >{{ item }}</span
      >
    </div>
  </div>
</template>
 
<script>
export default {
  props: {
    gallery: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      //默认显示
      index: 0,
      stopID: null,
      tableData: [],
      // typeList:[

      // ]
    };
  },
  // 当组件挂载完毕 执行自动播放
  created() {
    this.auto();
    this.getCarousel();
  },

  methods: {
    getCarousel() {
      this.$apiFun.getCarousel().then((res) => {
        console.log(res.data.data, "轮播图");
        this.tableData = [...this.tableData, ...res.data.data];
        console.log(this.tableData);
      });
    },
    //鼠标一开  auto自动执行
    auto() {
      this.stopID = setInterval(() => {
        this.index++;
        this.check();
      }, 2000);
    },
    overHd() {
      clearInterval(this.stopID);
    },
    //检查边界
    check() {
      if (this.index == this.tableData.length) {
        this.index = 0;
      }
    },
     goCarouselDetail(item){
        console.log(item)
         this.$router.push({ name: "CarouselDetail", query: { id: item.ruhui_id } });
      }
  },
};
</script>
 
<style scoped="scoped">
.swiper {
  position: relative;
}
.swiper:hover{
   cursor: pointer;
}
.swiper .thumbs {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
.title {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 1px;
}

.thumb {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 15px;
  text-indent: 9999em;
  margin: 0 5px;
  background-color: #fff;
}

.thumb:hover {
  cursor: pointer;
  background-color: #f70;
}

.active {
  background-color: #f70;
}
</style>