<template>
    <!-- 右侧导航 -->
    <div class="right-navbar">
      <div class="right-nav-div">
        <img src="@/assets/image/right_1.jpg" alt="" />
      </div>
      <div class="right-nav-div2">
        <img src="@/assets/image/right_2.jpg" alt="" />
        <div>
          <img style="width:183px;height:181px;position:absolute;left:0px;top:0px;" src="@/assets/image/right_2.5.jpg" alt="">
        </div>
      </div>
      <div class="right-nav-div" @click="getNotice">
        <img src="@/assets/image/right_3.jpg" alt="" />
      </div>
      <div class="right-nav-div" @click="backTop">
        <img src="@/assets/image/right_4.jpg" alt="" />
      </div>
    </div>
</template>
<script>
export default {
     name: "RightNavbar",
    data(){
        return{

        }
    },
    methods:{
    //我要入会
    getNotice(){
        this.$router.push({ name: "XieHui", params: { id: 4 } });
    },

        /*回到顶部实现过程注解：
                1.获取当前页面距离顶部的距离
                2.计算出每次向上移动的距离，用负的滚动距离除以5，因为滚动的距离是一个正数，想向上移动就是一个减法
                3.用当前距离加上计算出的距离，然后赋值给当前距离，就可以达到向上移动的效果
                4.最后在移动到顶部的时候，清除定时器*/
    backTop() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    }
}
</script>
<style scoped>
.right-navbar {
  width: 70px;
  height: 290px;
  /* background-color: red; */
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  position: fixed;
  top: 200px;
  right: 10px;
}
.right-nav-div {
  width: 70px;
  height: 70px;
  box-shadow: 0px 2px 3px #ccc;;
}
.right-nav-div:hover {
  cursor: pointer;
}
.right-nav-div2{
   width: 70px;
  height: 70px;
  box-shadow: 0px 2px 3px #ccc;;
}
.right-nav-div2 div{
  width: 200px;
  height: 181px;
  position: absolute;
  left: -200px;
  top: 71px;
  display: none;
}
.right-nav-div2:hover {
  cursor: pointer;
}
.right-nav-div2:hover div{
  display: block;
}
</style>