<template>
  <el-container style="min-height: 700px; border: 1px solid #eee">
    <el-header>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/Consultation' }"
          >质量安全资讯</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{menuTitle}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container>
      <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
        <div class="title">
          <span>质量安全资讯</span>
        </div>
        <el-menu
        @select="handleSelect"
        background-color="#d80d10"
        text-color="#fff"
        active-text-color="#ffd04b"
        :default-active='defaultIndex'>
          <el-menu-item
            collapse-transition="false"
            :index="item.url"
            v-for="(item,index) in menuList" :key="index">
            <i class="el-icon-s-help"></i>{{item.title}}
            <i class="el-icon-arrow-right" style="position:absolute;right:10px;line-height:56px"></i>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-main>
         <router-view :key="$route.fullPath" />
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>


<style>
.title {
  height: 60px;
  background-color: red;
  line-height: 60px;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
}
.el-header {
  height: 60px;
  background-color: #ffffff;
  color: #000;
  line-height: 60px;
}
.el-main{
    padding-top: 0px;
}

.el-aside {
  color: #333;
}
.el-breadcrumb {
  line-height: 60px;
}
</style>

<script>
export default {
  data() {
    return {
      menuTitle: "质量安全资讯",
      defaultIndex:'/QualityList',
      menuList: [
        {
          title: "质量安全资讯",
          url: "/QualityList",
          count: 1,
        }
      ],
      id: null,
    };
  },
  created() {
    this.getXiehui();
  },
  watch: {
    // $route(to, from) {
    //   window.location.reload(); //监测到路由发生跳转时刷新一次页面
    // },
  },
  methods: {
       handleSelect(key, keyPath) {
      console.log(key, keyPath);
      if(key == "/QualityList"){
        this.$router.push("/QualityList");
          this.menuTitle = "质量安全资讯"
      }
    },
    getXiehui() {
      this.id = this.$route.params.id;
      if (this.id == 1) {
         
        this.$router.push("/QualityList");
        // this.menuTitle="协会通知与公告"
        this.id = null;
      } 
      
      console.log(this.$route.params.id);
    },
  },
};
</script>