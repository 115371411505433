<template>
  <div class="block">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="valueList.page"
      :page-sizes="[valueList.limit]"
      :page-size="valueList.limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "Pagination",
  props: {
    valueList: Object,
    total: Number,
  },
  data() {
    return {
      // currentPage: 1,
    };
  },
  created() {
    console.log(this.valueList, this.total);
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);

    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.valueList.page = val
      console.log(this.valueList.page,"page")
      this.$emit('changePage',this.valueList.page)
    },
  },
};
</script>
<style>
.block {
  width: 100%;
  height: 80px;
  margin: 0 auto;
  text-align: center;
}

/*带背景的分页按钮背景色begin*/
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #08c0b9;
  color: #fff;
}
.el-pagination.is-background .el-pager li.active {
  background-color: #08c0b9;

  color: #fff;
  cursor: default;
}
/* .pagination.el-pagination.is-background .el-pager li:hover {
  color: #08C0B9;
}
.pagination.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #08C0B9;
}
.pagination.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  background-color: #08C0B9;
  color: #FFF;
} */
/*带背景的分页按钮背景色end*/

/*不带背景的分页按钮背景色begin*/
.el-pager li.active {
  color: #08c0b9;
  cursor: default;
}
/* .pagination.el-pagination .el-pager li:hover {
  color: #08C0B9;
}
.pagination.el-pagination .el-pager li:not(.disabled):hover {
  color: #08C0B9;
} */
/*不带背景的分页按钮背景色end*/
</style>