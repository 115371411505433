<template>
  <div>
    <!-- 内容顶部 -->
    <div class="main-header">
      <div class="title">
        <h2>{{ title }}</h2>
      </div>
      <div class="address">
        <span>发布时间：{{ time }}</span>
        <!-- &nbsp;&nbsp;&nbsp;
        <span>来源：</span> -->
      </div>
    </div>
    <div class="main-box">
      <!-- <p v-html="dataList.content"></p> -->
      <CommonVideo :src="address" ></CommonVideo>
    </div>
    <div style="width：100% ;height:200px"></div>
  </div>
</template>
<script>
import CommonVideo from './vedioPlay.vue'
export default {
  name: "NewDetail",
  components:{
    CommonVideo
  },
  data() {
    return {
      datalist: [],
      formData: {
        id: this.$route.query.id,
      },
      address:this.$route.query.address,
      // address:'',
      title: "",
      content: "",
      time: "",
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    // 富文本处理
    showHtml(str) {
      return str
        .replace(str ? /&(?!#?\w+;)/g : /&/g, "&amp;")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        .replace(/&amp;nbsp;/g, "\u3000");
    },

    getDetail() {
      this.$apiFun.getDetail(this.formData).then((res) => {
        console.log(res);
        this.datalist = res.data;
        console.log(this.datalist);
        this.time = this.datalist.issue_date;
        this.title = this.datalist.title;
        this.content = this.showHtml(this.datalist.content);
      });
    },
  },
};
</script>
<style  scoped>
/* .title{
    width: 90%;
    height: 60px;
    line-height: 60px;
    font-size: 24px;
    font-weight: 900;
} */
.box {
  width: 90%;
  height: 50px;
  font-size: 20px;
  border: 1px solid black;
  line-height: 50px;
}
.span {
  padding: 10px;
}
.main-header {
  width: 90%;
  height: 80px;
  padding-bottom: 30px;
  margin: 0 auto;
}

.title {
  width: 100%;
  min-height: 30px;
  /* padding: 10px; */
  font-size: 21px;
  font-weight: bold;

  margin: 0 auto;
  line-height: 30px;
  word-break: break-all;
  display: flex;
  align-items: center;
  justify-content: center;
}
.address {
  width: 100%;
  height: 30px;
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
  line-height: 30px;
}
.main-box {
  width: 90%;
  min-height: 700px;
  margin: 0 auto;
  line-height: 30px;
}
</style>
